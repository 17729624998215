<template>
  <div>
         <v-row dense class="mt-2">
            <v-toolbar color="secondary" dark>
                <span class="mt-2">{{$moment(selected_draw.date).format("MMM DD, YYYY")}} {{selected_draw.time}}</span>
                  <v-spacer/>
                  <span style="width: 130px" >
                 
                     <v-select
                       dense
                       v-model="selected_draw"
                       outlined
                       label="Draw Time"
                       item-text="time"
                       hide-details
                       :items="OPEN_DRAWS"
                       return-object
                     />
                  </span>
            </v-toolbar>   
           
             <v-col cols="12" class="pa-3">
               
               <!-- <v-row no-gutters class="text-primary"   v-if="OPEN_DRAWS.length>0">
                
               </v-row> -->
               <v-row  v-if="$store.state.watchdraw==true">
                 <v-col cols="12" class="pa-2">
                   <v-btn color="success" x-large @click="setOpenMachine(true)" block> <v-icon color="error" left>mdi-play-circle</v-icon> watch Draw</v-btn>
                 </v-col>
               </v-row>
                   <table class="va-table print_table3 mt-2 text-left" cellspacing="0">
                       <tr>
                           <td class="pa-1 md-font _headtext-2" style="background: #f7a61d;">COMBINATION</td>
                           <td class="text-center pa-1 md-font" style="background: #f7a61d;"></td>
                           <td class="text-right pa-1 md-font _headtext-2" style="background: #f7a61d;">AMOUNT</td>
                       </tr>
                       <tr v-for="(item, i) in Lines" :key="i">
                           <td class=" _headtext-2" style="background: #fefefe;"><v-icon left small color="error" @click="removeline(i)">mdi-close</v-icon> {{item.num1}} - {{item.num2}}</td>
                           <td class="text-center  _headtext-2"  style="background: #fefefe;">{{item.line_type}}</td>
                           <td class="text-right  _headtext-2" colspan="2"  style="background: #fefefe;">{{$money(item.line_amount)}}</td>
                       </tr>
                   </table>
                   <br />
                   
                   <v-row no-gutters class="text-primary">
                     <p></p>
                     <v-spacer/>
                     <h4 class="_headtext-2">TOTAL:  {{$money(TOTAL)}}</h4>
                   </v-row>
                  
             </v-col>
          
         </v-row>
         <template v-if="OPEN_DRAWS.length > 0">
                   <v-bottom-navigation  grow class="elevation-0 mt-5" >
                     <v-btn value="clear" @click="clear()" color="secondary">
                      <span class="text-error _headtext-md">CLEAR</span>
                       <v-icon  color="error">mdi-cancel</v-icon>
                     </v-btn>
 
                     <v-btn @click="openNumpad()" color="secondary">
                       <span class="text-gold _headtext-md">ADD BET</span>
                       <v-icon color="info" >mdi-plus</v-icon>
                     </v-btn>
 
                     <v-btn  @click="ValidateTicket()" color="secondary">
                      <span class="text-success _headtext-md">PROCEED</span>
                       <v-icon color="success">mdi-arrow-right</v-icon>
                     </v-btn>
                   </v-bottom-navigation>
         </template>
         <template v-else>
           <v-alert
                   dense
                   outlined
                   type="info"
                   >
                     No open draws at the moment!
                   </v-alert>
         </template>
         
         <va-numpad :show="numpad" :gamename="gamename" :nums="38" @DialogEvent="numpadEvent"/>
         <va-ticket-preview :show="preview" :lines="validated_lines" :ticket="ticket" @DialogEvent="previewEvent"/>
          <va-ticket :show="ticket_dailog" :ticket="ticket" @DialogEvent="ticketEvent"/>
           <va-ticket-pop :show="ticketpop" :tickets="tickets" @DialogEvent="tpEvent"/>
   </div>
 </template>
 <script>
 import {
   mapMutations
 } from 'vuex'
   export default {
     data: () => ({
       gamename: 'STL-SILAY',
       drform: false,
       numpad: false,
       preview: false,
       ticket_dailog: false,
       selected_draw:{},
       Lines:[],
       results:[],
       validated_lines:[],
       ticket:{},
       ticketpop: false,
       tickets:[]
     }),
     created() {
      // console.log('Created')
       this.PING()
       
     },
      mounted(){
       //   console.log('mounted')
        this.setLoggedIn(true)
         if (this.user == null) {
           this.$router.push('/')
         }else {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
           this.GET_OPEN_GAMES(this.gamename)
           if(this.user.role == 'Player') {
             this.setBettorName(this.user.fullname)
           }
           setTimeout(()=>{
             this.selected_draw = this.OPEN_DRAWS[0]
           }, 800)
         }
     },
     computed:{
        user() {
         if(localStorage.user){
             return JSON.parse(localStorage.user)
         }
         return null
       },
       OPEN_DRAWS() {
        return this.$store.state.open_2d_draws.length > 0 ? this.$store.state.open_2d_draws:[]
       },
       TOTAL() {
            return this.Lines.reduce((res,item)=>res += this.$IsNum(item.line_amount), 0)
       },
       BALANCE(){
         return  this.$store.state.balance.balance - this.TOTAL
       },
        RESULTS(){
          console.log(this.$store.state.results)
          var resdata = this.$store.state.results.reduce((res,item)=>{
             if(item.game == this.gamename) {
                res[item]
             }
             return res
          }, [])
         return  resdata
       }
     },
     methods: {
       ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', "setBettorName"]),
     tpEvent(){
       this.ticketpop = false
     },
     openNumpad(){
        if(this.user.role == 'Player') {
             this.setBettorName(this.user.fullname)
           }
           this.numpad = true
     },
       previewEvent(e){
         this.preview = false
         if(e != 'cancel') {
           if(e.status) {
             this.ticket = e.ticket
             //console.log(this.ticket)
             this.ticket_dailog = true
             this.CHECK_BALANCE(this.user.id)
           } else {
               this.VA_ALERT("error", e.message)
           }
         }
       },
      
       ticketEvent(e){
         this.ticket_dailog = false
         if(e=="done"){
             this.clear()
         }
         
       },
       ValidateTicket() {
         if(this.Lines.length == 0) {
            this.VA_ALERT("error", "Select your combination!")
           return 
         }
       
         var ticket = {}
         ticket.Lines = this.Lines
         ticket.amount = this.TOTAL.toString()
         ticket.game=this.gamename
         ticket.draw_date = this.selected_draw.date + " " + this.selected_draw.time
         ticket.draw_id = this.selected_draw.id
         this.ValidateNewTicket(ticket)
       },
       clear(){
         this.Lines=[]
           this.ticket={}
       },
       ValidateNewTicket(ticket){
         this.validated_lines=[]
         console.log(ticket)
          this.$http.post("ticket/validate_new", ticket).then(response => {
                  console.log(response.data)
                 if( response.data.status) {
                   this.validated_lines = response.data.newlines
                    this.ticket = ticket
                   this.preview = true
                 } else {
                   this.VA_ALERT('error', response.data.message )
                 }
                }).catch(e => {
                 console.log(e.data)
             })
          
       },
      
       removeline(i){
         this.Lines.splice(i,1)
       },
       numpadEvent(data){
         // console.log(data)
         // console.log(this.Lines)
         this.numpad = false
         if(data != "cancel"){
           if(data.line_amount != 'close') {
             this.setBettorName(data.bettor)
             //this.Lines.push(data.line)
             if(data.lines[0] != null) {
              this.AddLines(data.lines[0])
             }
             if(data.lines[1] != null) {
              this.AddLines(data.lines[1])
             }
           
           }
           
         }
         console.log(this.Lines)
       },
       AddLines(line) {

          if(this.Lines.length==0) {
            this.Lines.push(line)
            return 
          }
          var dup = false
          for(var i = 0; i < this.Lines.length; i++) {
              if(line.num1 == this.Lines[i].num1 && line.num2 == this.Lines[i].num2) {
                if(this.Lines[i].line_type == line.line_type) {
                  dup = true
                  this.Lines[i].line_amount = this.$IsNum(this.Lines[i].line_amount) +  this.$IsNum(line.line_amount)
                  this.Lines[i].line_amount = this.Lines[i].line_amount.toString()
                  this.Lines[i].total_amount = this.Lines[i].line_amount
                }
              } 
          }
          if(!dup) {
            this.Lines.push(line)
          }

      }
     },
   }
 </script>